import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 843.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,843.000000) scale(0.100000,-0.100000)">


<path d="M4717 6882 c-26 -11 -79 -12 -292 -6 -289 8 -1059 8 -1081 0 -13 -5
-14 -232 -12 -1628 l3 -1623 23 -70 c13 -38 29 -83 37 -98 8 -16 15 -33 15
-39 0 -15 93 -168 102 -168 4 0 8 -4 8 -10 0 -5 7 -18 15 -27 55 -62 105 -110
158 -152 89 -69 271 -161 320 -161 14 0 42 -6 64 -14 32 -11 221 -14 1058 -16
561 -2 1089 -2 1173 -1 l152 1 0 155 0 156 -52 -40 c-128 -96 -267 -136 -441
-128 -134 6 -202 23 -322 82 -211 102 -372 300 -434 535 -134 502 126 980 607
1112 111 31 353 31 464 0 189 -52 329 -150 422 -295 l36 -56 -58 -44 c-31 -25
-91 -70 -132 -100 -41 -31 -80 -61 -87 -66 -20 -17 -26 -13 -55 32 -46 72
-127 135 -223 172 -38 15 -75 20 -155 20 -98 0 -110 -2 -173 -32 -125 -59
-224 -192 -257 -344 -17 -77 -8 -278 15 -344 29 -83 102 -183 167 -231 105
-77 256 -104 385 -70 121 32 309 174 301 227 l-3 24 -165 2 -165 2 -3 145 -3
146 336 0 335 0 3 -382 c1 -211 2 -449 2 -531 l0 -147 266 0 c214 0 269 3 278
14 16 19 10 3356 -6 3406 -33 102 -64 175 -96 225 -25 40 -106 141 -139 172
-43 41 -156 116 -199 132 -19 7 -43 18 -54 24 -30 16 -138 37 -240 48 -49 5
-489 8 -978 7 -732 -2 -893 -4 -920 -16z m1228 -943 c205 -29 385 -119 538
-267 80 -79 161 -193 203 -289 14 -31 36 -100 50 -155 21 -84 23 -105 14 -129
-14 -32 -40 -44 -75 -35 -30 8 -43 31 -56 103 -43 232 -227 468 -445 569 -131
61 -203 77 -366 79 -103 1 -151 5 -162 14 -21 17 -21 75 0 92 32 27 179 36
299 18z m-525 -249 l0 -230 -375 0 -375 0 0 -23 c0 -12 -8 -67 -19 -122 -10
-55 -22 -128 -26 -162 l-7 -62 32 15 c96 48 312 40 462 -17 146 -56 266 -149
344 -266 19 -28 34 -55 34 -60 0 -5 -15 -17 -32 -28 -93 -53 -241 -213 -302
-326 -43 -81 -92 -214 -111 -304 -18 -86 -25 -279 -12 -356 l8 -47 -73 -17
c-102 -23 -279 -30 -390 -15 -165 22 -320 87 -437 182 -57 46 -141 150 -141
175 0 8 57 79 127 156 69 78 130 146 134 151 4 5 42 -23 85 -63 241 -225 566
-227 676 -3 19 39 23 63 23 142 0 88 -2 99 -31 150 -35 62 -97 115 -152 129
-134 35 -252 5 -339 -85 l-42 -44 -216 0 c-142 0 -215 4 -215 10 0 6 7 43 15
83 8 40 26 135 39 212 14 77 38 205 54 285 16 80 53 269 81 420 29 151 57 292
62 313 l10 37 554 0 555 0 0 -230z m467 -30 c290 -39 516 -238 577 -508 21
-94 6 -131 -55 -132 -35 0 -54 25 -68 93 -17 78 -72 185 -127 246 -51 57 -148
121 -218 145 -68 23 -183 38 -238 31 -39 -6 -50 -3 -67 14 -25 25 -27 73 -5
92 9 8 40 17 68 20 28 3 52 7 53 8 1 0 37 -4 80 -9z m73 -308 c31 -12 81 -44
111 -71 119 -106 162 -301 66 -301 -38 0 -64 27 -77 79 -27 113 -110 179 -241
190 -66 6 -85 11 -99 28 -22 27 -15 68 14 89 30 22 154 14 226 -14z m-85 -299
c34 -38 33 -77 -5 -115 -26 -25 -36 -29 -63 -24 -18 3 -42 17 -54 32 -72 81
48 186 122 107z"/>
<path d="M4354 2597 c-3 -8 -4 -180 -2 -383 3 -350 4 -371 23 -404 31 -53 76
-72 155 -66 85 6 132 23 126 45 -2 9 -7 33 -11 53 l-6 37 -50 -2 c-82 -3 -79
-16 -79 385 l0 348 -75 0 c-54 0 -77 -4 -81 -13z"/>
<path d="M3010 2520 l0 -70 135 0 135 0 0 -350 0 -350 80 0 80 0 0 350 0 350
135 0 135 0 0 70 0 70 -350 0 -350 0 0 -70z"/>
<path d="M3877 2370 c-103 -18 -210 -109 -240 -205 -6 -22 -12 -76 -11 -120 1
-94 27 -156 91 -216 71 -68 115 -84 228 -84 89 0 106 3 157 28 50 24 128 96
128 117 0 4 -27 15 -61 25 l-61 17 -27 -25 c-47 -44 -89 -60 -145 -53 -28 3
-62 14 -76 24 -35 26 -70 80 -70 108 l0 24 240 0 240 0 0 43 c-1 210 -178 353
-393 317z m171 -145 c22 -20 43 -44 46 -55 3 -11 9 -29 12 -40 5 -19 1 -20
-161 -20 l-166 0 7 23 c16 52 24 65 58 95 33 29 43 32 100 32 57 0 66 -3 104
-35z"/>
<path d="M4912 2364 c-68 -18 -85 -27 -140 -78 -75 -68 -112 -185 -91 -286 22
-105 77 -183 163 -228 46 -24 61 -27 156 -27 90 0 111 4 150 24 49 25 130 98
130 117 0 6 -27 19 -61 28 l-60 17 -29 -30 c-30 -31 -83 -51 -135 -51 -45 0
-107 40 -132 85 -44 78 -53 75 217 75 l240 0 0 55 c0 155 -109 280 -267 305
-72 12 -76 12 -141 -6z m175 -125 c18 -12 42 -38 53 -60 36 -70 39 -69 -140
-69 -157 0 -160 0 -160 21 0 32 38 88 74 110 43 27 128 26 173 -2z"/>
<path d="M5629 2370 c-99 -17 -191 -88 -232 -178 -31 -69 -31 -198 1 -267 29
-63 83 -119 147 -153 44 -23 62 -26 150 -27 81 0 109 4 145 21 47 21 130 98
130 119 0 10 -47 29 -132 51 -9 3 -22 -4 -30 -14 -37 -53 -145 -67 -200 -26
-91 68 -104 228 -24 303 57 54 155 59 207 10 17 -16 37 -29 43 -29 16 0 136
41 136 46 0 30 -102 114 -160 132 -53 16 -130 21 -181 12z"/>
<path d="M6269 2370 c-94 -16 -174 -77 -220 -168 -25 -50 -29 -68 -29 -142 0
-68 5 -95 24 -136 32 -69 79 -120 140 -152 45 -23 63 -26 151 -27 114 0 167
19 230 82 165 165 100 464 -115 531 -52 16 -129 21 -181 12z m159 -156 c119
-91 82 -312 -57 -340 -142 -29 -245 138 -173 281 30 61 69 84 141 85 43 0 61
-5 89 -26z"/>
<path d="M7072 2369 c-49 -9 -96 -36 -131 -73 -14 -14 -28 -26 -33 -26 -4 0
-8 23 -8 50 l0 51 -67 -3 -68 -3 -3 -307 -2 -308 80 0 80 0 0 189 c0 179 1
190 23 222 69 103 188 103 217 1 5 -20 10 -121 10 -224 l0 -188 75 0 75 0 0
177 c0 159 2 181 21 220 34 71 108 108 167 84 53 -22 57 -38 60 -268 l3 -213
74 0 74 0 7 47 c9 63 -3 408 -16 454 -23 82 -80 122 -176 122 -78 0 -140 -29
-189 -85 l-34 -41 -11 29 c-30 78 -120 115 -228 93z"/>
<path d="M3034 1526 c-3 -7 -4 -35 -2 -62 l3 -49 1798 -3 1797 -2 0 65 0 65
-1795 0 c-1516 0 -1796 -2 -1801 -14z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
